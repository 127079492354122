import React, {useEffect, useState, useContext} from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FabContext } from "../../context/FabContext";
import Slider from "react-slick";
import Layout from "../../components/layout"
import './style.scss';

const Testimonial = () => {

    const{testimonials,setLocal} = useContext(FabContext);

    const [progress,setProgress] = useState('11.11%');

    function slideChange(e){ 
            setProgress(`${((e+1)/(testimonials.length))*100}%`);
    }

    let bridesSlider = null;
    // function next() { bridesSlider.slickNext()}
    // function previous() { bridesSlider.slickPrev()}

    // function logit() {
    //     if(window.pageYOffset > scrollY){ next(); }
    //     else{ previous(); }
    //     setScrollY(window.pageYOffset);
    // }

    useEffect(()=>{ setLocal('test'); },[]);

    const settings = {
        speed: 500,
        prevArrow: false,
        nextArrow: false,
        afterChange: slideChange,
        dots: false,
        arrows:false,
        infinite:false,
        draggable: true,
        cssEase:'ease-in-out',
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
              breakpoint: 786,
              settings: { 
                draggable: true,
                slidesToShow:1,
              }
            }
          ],
    };

    const brides = testimonials.map((test,index)=> 
        <AniLink cover direction="right" bg="#ffffff" duration={1} to={`/testimonials/${test.id}`}>
            <div  className="bride" key={index} >
                <h3 className="name">{test.name}</h3> 
                <img alt={test.name} src={`../images/testimonials/${test.url}`} />
            </div>
        </AniLink> )

    return(
        <Layout>
            <div  className="testimonials">
                <div className="testimonials__main">
                <Slider ref={c => (bridesSlider = c)} className="testimonials__main__grid"  {...settings} > {brides} </Slider>
                <div className="testimonials__main__prog">
                    <button onClick={() => bridesSlider.slickPrev()} className="testimonials__main__prog__prev">&#8592;</button>
                    <div style={{width: progress}} className="testimonials__main__prog__current"></div>
                    <button onClick={() => bridesSlider.slickNext()} className="testimonials__main__prog__next">&#8594;</button>
                </div>
                </div>
            </div>
        </Layout>
    )
}
export default Testimonial;
